.Logo {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 95px; }
  .Logo path {
    fill: rgba(3, 6, 0, 0.8); }
  @media (max-width: 575px) {
    .Logo {
      height: 65px; } }
