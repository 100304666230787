.Footer {
  margin-top: auto;
  background-color: #2a6897; }
  .Footer * {
    color: #fafafa; }
  .Footer a {
    color: #fafafa; }
    .Footer a:hover {
      color: #dbdbdb; }
    .Footer a.active {
      font-weight: bold; }
  @media (max-width: 767px) {
    .Footer .content-flex-end {
      display: flex;
      justify-content: flex-end; } }
