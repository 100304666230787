.NavItems, .NavItems ul, .NavItems li {
  list-style: none; }

@media (max-width: 767px) {
  .NavItems {
    padding: 0; }
  .NavItems .nav-link {
    padding: 10px 0; }
    .NavItems .nav-link .nav-item {
      background-color: #e0e0e0 !important;
      color: #030600;
      padding: 12px; }
    .NavItems .nav-link.active .nav-item {
      padding: 12px;
      background-color: #c7c7c7 !important; } }

@media (min-width: 768px) {
  .NavItems .active {
    background-color: #bbc4d0; }
  .NavItems .nav-link .nav-item {
    padding: 26px 26px; }
  .NavItems .nav-link:hover {
    background-color: #9daabb; }
  .NavItems .nav-link.active,
  .NavItems .nav-link.active .nav-item, .NavItems .nav-link:visited,
  .NavItems .nav-link:visited .nav-item {
    background-color: #bbc4d0; }
  .NavItems .nav-link:hover,
  .NavItems .nav-link:hover .nav-item {
    background-color: #9daabb; } }

@media (min-width: 768px) and (max-width: 991px) {
  .NavItems .nav-item {
    font-size: 0.9em; } }

@media (min-width: 992px) and (max-width: 1500px) {
  .NavItems .nav-item {
    font-size: 0.9em; } }
