.Navigation {
  position: fixed;
  top: 0;
  z-index: 100;
  width: 100%;
  background-color: #e8ebef; }
  .Navigation * {
    color: #030600; }
    .Navigation *:hover {
      color: #030600; }
  .Navigation nav {
    flex-wrap: nowrap;
    padding: 0; }
    .Navigation nav .brandLogo {
      width: 210px; }
  .Navigation .push-end {
    flex-direction: row-reverse; }
  .Navigation .MenuIcon svg {
    width: 35px;
    height: 35px;
    margin-right: 15px; }
  .Navigation .navbarBrandLogo:hover {
    text-decoration: none; }

.SideNav ul, .SideNav li {
  list-style: none; }

.SideNav nav {
  background-color: #fffcfd !important; }
  .SideNav nav li {
    background-color: #fffcfd !important;
    cursor: default !important; }
  .SideNav nav h1 {
    background-color: #e8ebef !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important; }

.SideNav p {
  font-size: 16px; }
