.ImageSlider {
  padding-top: 115px; }
  @media (max-width: 575px) {
    .ImageSlider {
      padding-top: 60px; } }
  .ImageSlider .slick-initialized {
    width: 100%; }
  .ImageSlider .slick-list {
    max-height: 750px; }
  .ImageSlider .slick-slider {
    overflow: hidden; }
  .ImageSlider .slick-slide[aria-hidden="true"] img {
    visibility: hidden; }

@-webkit-keyframes kenburns {
  0% {
    opacity: 0; }
  5% {
    opacity: 1; }
  95% {
    -webkit-transform: scale3d(1.5, 1.5, 1.5) translate3d(-190px, -120px, 0px);
            transform: scale3d(1.5, 1.5, 1.5) translate3d(-190px, -120px, 0px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 1; }
  100% {
    -webkit-transform: scale3d(2, 2, 2) translate3d(-170px, -100px, 0px);
            transform: scale3d(2, 2, 2) translate3d(-170px, -100px, 0px);
    opacity: 0; } }

@keyframes kenburns {
  0% {
    opacity: 0; }
  5% {
    opacity: 1; }
  95% {
    -webkit-transform: scale3d(1.5, 1.5, 1.5) translate3d(-190px, -120px, 0px);
            transform: scale3d(1.5, 1.5, 1.5) translate3d(-190px, -120px, 0px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 1; }
  100% {
    -webkit-transform: scale3d(2, 2, 2) translate3d(-170px, -100px, 0px);
            transform: scale3d(2, 2, 2) translate3d(-170px, -100px, 0px);
    opacity: 0; } }

@keyframes myMove {
  from {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%; }
  to {
    -webkit-transform: scale(1.3, 1.3);
            transform: scale(1.3, 1.3);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%; } }

@-webkit-keyframes myMove {
  from {
    -webkit-transform: scale(1, 1);
    -webkit-transform-origin: 50% 50%; }
  to {
    -webkit-transform: scale(1.3, 1.3);
    -webkit-transform-origin: 50% 0%; } }
