@media (max-width: 767px) {
  .BrandLogo svg {
    padding-left: 45px; } }

@media (min-width: 768px) {
  .BrandLogo svg {
    padding-left: 275px; } }

.BrandLogo p {
  margin: 0;
  font-size: 22px; }
  @media (max-width: 767px) {
    .BrandLogo p {
      margin-left: 45px; } }
  @media (min-width: 768px) {
    .BrandLogo p {
      margin-left: 250px; } }
  @media (max-width: 575px) {
    .BrandLogo p {
      font-size: 16px; } }
